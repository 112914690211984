<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12>
        Amount in account: {{account.accountCurrency}} {{account.accountAmount}}
      </v-flex>
      <v-flex xs12>
        Account Type: {{account.accountType}}
      </v-flex>
      <v-flex xs12>
        Account Description: {{account.accountDescription}}
      </v-flex>
    </v-layout>
</v-container>
</template>

<script>
    import { formatMoney } from '../../javascript/globalFunctions'

  export default {
    props: ['accountId'],
    data() {
      return {
        account: []
      }
    },
    beforeMount() {
      let acc = this.$store.state.Accounts
      for (let i = 0; i < acc.length; i++) {
        if (acc[i].id === this.accountId) {
          this.account = acc[i]
          break
        }
      }

        this.account.accountAmount = formatMoney(this.account.accountAmount)

    }
  }
</script>
